import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import SkillBar from 'react-skillbars';

import { skillsData } from '../../data/data';

import Title from '../Title/Title';

const Skills = () => {
  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="skills">
      <Container>
        <Title title="Technical Skills" />
        <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={500} distance="30px">
        {skillsData.map((skills_set) => {
            const { title, skills, colors, id} = skills_set;
            return (
              <div key={id}>
                <h3>{title}</h3>
                <SkillBar skills={skills} colors={colors} animationDelay={500}/>
                <br />
              </div>
              )
          })}
        </Fade>
      </Container>
    </section>
  );
};

export default Skills;
