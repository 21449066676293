import React from 'react';
import { Container } from 'react-bootstrap';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

import Title from '../Title/Title';
import { ReactComponent as WorkIcon } from '../../assets/workIcon.svg';
import { ReactComponent as SchoolIcon } from '../../assets/educationIcon.svg';
import { ReactComponent as StarIcon } from '../../assets/starIcon.svg';
import { timelineData } from '../../data/data';

const Timeline = () => {
  return (
    <section id="timeline">
      <Container>
      <Title title="Experiences" />
      <VerticalTimeline>
        {timelineData.map((event) => {
          return (
            <VerticalTimelineElement
            className={`vertical-timeline-element--${event.type === 'WORK' ? 'work': 'education'} ${event.highlight ? 'main': ''}`}
            date={event.date}
            icon={event.type === 'WORK' ? <WorkIcon /> : <SchoolIcon /> }
          >
            <h3 className="vertical-timeline-element-title">{event.heading}</h3>
            <h4 className="vertical-timeline-element-subtitle">{event.subheading}</h4>
            <div>
              {event.texts.map((text) => {
                return <p><i class="fa fa-minus"></i> {text}</p>
              })}
            </div>
          </VerticalTimelineElement>
          )
        })}
        <VerticalTimelineElement
          iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
          icon={<StarIcon />}
        />
    </VerticalTimeline>
    </Container>
  </section> 
  );
};

export default Timeline;
