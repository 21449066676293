// HEAD DATA
export const headData = {
  title: 'Jessica Donahue', // e.g: 'Name | Developer'
  lang: '', // e.g: en, es, fr, jp
  description: 'Welcome to my website', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Hi, my name is',
  name: 'Jess',
  subtitle: 'I\'m a Full-Stack Developer',
  cta: 'Learn more',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpg',
  paragraphOne: `I am a New Yorker, born and raised in Manhattan. I first discovered my interest
    for technology at a young age. Picking science kits and video games over Barbie dolls. I didn't just 
    enjoy playing video games, I wanted to learn how to build them. Taking my first computer science 
    class my freshman year at NYU, I very quickly realized that programming not only came easy to me 
    but I had a passion for it.`,
  paragraphTwo: `I am a web developer with a background in Computer Science, passionate about building responsive,
    accessible, data-driven web applications using JavaScript, HTML, and CSS.`,
  paragraphThree: `While my focus is currently on front-end technologies, I have experience with designing
    non-relational databases such as DynamoDB and MongoDB and building services
    with AWS Lambda in Node.js.`,
  resume: '/JessicaDonahue.pdf', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: 'project1',
    img: 'murad.jpg',
    title: 'Murad Skincare',
    info: 'Technologies:',
    info2: 'BigCommerce ecommerce Platform, Contentful CMS, Vue.js',
    bullets: [
      'BigCommerce ecommerce Platform',
      'Contentful CMS',
      'Vue.js'
    ],
    url: 'https://www.murad.com/',
    repo: '',
  },
  {
    id: 'project2',
    img: 'verizon.png',
    title: 'Verizon',
    info: 'Technologies:',
    info2: 'ReactJS, HTML, CSS',
    bullets: [
      'ReactJS',
      'HTML',
      'CSS'
    ],
    url: 'https://www.verizon.com/plans/unlimited/?cmp=KNC-C-HQ-PRO-R-BP-NONE-UnlimitedBuiltRight-2K0PX0-PX-GAW-71700000020646874&ds_rl=1275402&gclid=EAIaIQobChMInJme7sfh6gIVQyCtBh22yAIQEAAYASAAEgJr3_D_BwE&gclsrc=aw.ds',
    repo: '',
  },
  {
    id: 'project3',
    img: 'palms.jpg',
    title: 'Palms Casino Resorts',
    info: 'Technologies:',
    info2: 'Vue.js, Contentful',
    bullets: [
      'Vue.js',
      'Contentful'
    ],
    url: 'https://www.akqa.com/work/palms/from-dust-to-gold/',
    repo: '',
  },
];

// TIMELINE DATA
export const timelineData = [
  {
    type: 'WORK',
    heading: 'Developer',
    subheading: 'AKQA',
    date: 'Aug 2019 - present',
    texts: [
      'Currently working on app for health company, using Molesoft for the API management, Node.js for back-end services, MySQL database, and ContentStack CMS',
      'Built new ecommerce site for Murad Skincare, implementing BigCommerce ecommerce platform and Contentful content management'
    ],
    highlight: true,
  },
  {
    type: 'WORK',
    heading: 'Associate Developer',
    subheading: 'AKQA',
    date: 'Jun 2017 - July 2019',
    texts: [
      'Create both production ready web modules as well as high functioning prototypes and proof of concepts for Verizon’s main website, using JavaScript, ReactJS, HTML5, and CSS',
      `Developed both the UI and CMS for Palms Casino Resort’s dynamic microsite using both Vue.js and Contentful`
    ],
  },
  {
    type: 'SCHOOL',
    heading: 'New York University',
    subheading: `Bachelor's degree, Computer Science`,
    texts: [
      'GPA: 3.6',
      'Activities and societies: Women in Science Peer Mentoring Program, Tech@NYU',
      `Awards: University Honors Scholar/Founder's Day Award`
    ],
    highlight: true,
  },
  {
    type: 'WORK',
    heading: 'Web Developer',
    subheading: 'NYU College of Arts and Science',
    texts: [
      'Assist in the migration of NYU’s sites and assets from EM3 iOn CMS to Adobe Experience Manager',
      'Coordinate and lead training sessions for faculty to gain basic knowledge of AEM',
      'Regularly update NYU’s sites based upon requests by faculty and provide troubleshooting support'
    ],
  },
  {
    type: 'WORK',
    heading: 'IT Support Assistant',
    subheading: 'NYU Stern School of Business',
    texts: [
      'Assist students, faculty, and staff in the use of technology, computer hardware and software.',
      'Responsible for the installation, upgrade, and troubleshooting of computers and printers.'
    ],
  },
  {
    type: 'WORK',
    heading: 'Instructor and Tech Coordinator',
    subheading: 'ID Tech Camps',
    texts: [
      'Assisted in the teaching of JavaScript, Web Design, and Scratch programming courses',
    ],
  },
  {
    type: 'SCHOOL',
    heading: 'Rye High School',
    subheading: 'AKQA',
    texts: [
      'GPA: 4.0',
      'Graduated Top 5% of Class',
      'Awards: National Honor Society, AP Scholar Award, Fordham Book Award'
    ],
  },
]

// SKILLS DATA
export const skillsData = [
  {
    id: 'skills1',
    title: 'Languages',
    skills: [
      {type: "Javascript", level: 100},
      {type: "Python", level: 75},
      {type: "Java", level: 50},
    ],
    colors: {
      "bar": "#3498db",
      "title": {
        "text": "#fff",
        "background": "#2980b9"
      }
    },
  },
  {
    id: 'skills2',
    title: 'Frameworks',
    skills: [
      {type: "ReactJS", level: 100},
      {type: "Express", level: 100},
      {type: "Vue.js", level: 60},
    ],
    colors: {
      "bar": "#2ecc71",
      "title": {
        "text": "#fff",
        "background": "#27ae60"
      }
    }
  },
  {
    id: 'skills3',
    title: 'Technologies',
    skills: [
      {type: "MySQL", level: 100},
      {type: "CMS", level: 100},
      {type: "AWS", level: 80},
      {type: "Mulesoft API", level: 80},
    ],
    colors: {
      "bar": "#5a68a5",
      "title": {
        "text": "#fff",
        "background": "#46465e"
      }
    }
  }
]

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: 'jessicamdonahue@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: 'footer1',
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/jessica-donahue-826a03114',
    },
    {
      id: 'footer2',
      name: 'github',
      url: 'https://github.com/jessicadonahue',
    },
    {
      id: 'footer2',
      name: 'envelope-o',
      url: `mailto:${contactData.email}`,
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: true, // set to false to disable the GitHub stars/fork buttons
};
