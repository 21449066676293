import React from 'react';
// import logo from './logo.svg';
import Hero from './components/Hero/Hero';
import About from './components/About/About';
import Timeline from './components/Timeline/Timeline';
import Projects from './components/Projects/Projects';
import Skills from './components/Skills/Skills';
import Contact from './components/Contact/Contact';
import Footer from './components/Footer/Footer';

import 'bootstrap/dist/css/bootstrap.min.css';
import './style/main.scss';

function App() {
  return (
    <>
    <Hero />
    <About />
    <Timeline />
    <Projects />
    <Skills />
    <Contact />
    <Footer />
    </>
  );
}

export default App;
